<template>
  <b-card
    class="ecommerce-card cursor-pointer"
    no-body
  >
    <router-link :to="{ name: 'mentor-page', params: { id: mentor.id } }">
      <div class="p-1">
        <div class="position-absolute d-flex flex-column align-items-start">
          <b-badge
            v-if="mentor.is_new"
            class="mentor-badge"
            variant="warning"
            pill
          >
            {{ $t('New') | upper }}
          </b-badge>
          <b-badge
            v-if="mentor.is_gww"
            class="mentor-badge"
            variant="info"
            pill
          >
            GWW
          </b-badge>
        </div>
        <div class="mx-2">
          <b-img
            :alt="mentorFullname"
            fluid
            class="card-img-top grayscale-img"
            :src="mentor.user.photo_full_path"
          />
        </div>
      </div>
      <b-card-body class="m-1">
        <b-card-title class="mb-1">
          {{ mentorFullname }}
        </b-card-title>
        <b-card-sub-title class="mb-4">
          {{ getMentorVisibleProfessionalTitle(mentor) }}
        </b-card-sub-title>
        <b-card-text>
          {{ getMentorVisibleDescription(mentor) }}
        </b-card-text>
      </b-card-body>
      <b-card-footer footer-class="card-footer-class">
        <b-button
          variant="primary"
          class="w-100"
        >
          {{ $t('More') }}
        </b-button>
      </b-card-footer>
    </router-link>
  </b-card>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  // BCardImg,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BImg
} from 'bootstrap-vue'
import { upper } from '@mentoring-platform/utils/filters'
import { useMentorHelpers } from '@mentoring-platform/composables'

export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    // BCardImg,
    BCardSubTitle,
    BCardText,
    BCardTitle,
    BImg
  },
  filters: {
    upper
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    mentorFullname() {
      return this.getMentorVisibleFullName(this.mentor)
    }
  },
  setup() {
    const { getMentorVisibleFullName, getMentorVisibleProfessionalTitle, getMentorVisibleDescription } = useMentorHelpers()

    return {
      getMentorVisibleDescription,
      getMentorVisibleFullName,
      getMentorVisibleProfessionalTitle
    }
  },
}
</script>

<style lang="scss" scoped>

.card-footer-class {
  border: 0;
  padding-top: 0;
}

.card-text {
  color: #6e6b7b;
}

.mentor-badge {
  margin-bottom: 5px;
  padding: 5px 20px;
  z-index: 10;
}
</style>
