import { nextTick } from '@vue/composition-api'

export default {
  data() {
    return {

    }
  },
  methods: {
    scrollToTop() {
      nextTick(() => {
        const rootElement = document.documentElement
        rootElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        })
      })
    }
  }
}