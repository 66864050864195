<template>
  <b-modal
    id="language-filter-modal"
    ref="language-filter-modal"
    centered
    size="md"
    ok-only
  >
    <b-card-text class="m-2">
      Use the language filter to view all our mentors who can provide mentoring in english!
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BModal
  },
  data() {
    return {

    }
  },
  methods: {
    show() {
      this.$refs['language-filter-modal'].show()
    }
  }
}
</script>
